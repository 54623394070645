import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import Text from "@ui/text";
import gql from "graphql-tag";
import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { Switch } from "@src/ui/switch";
import { Input } from "@src/ui/input";
import { GraphqlOperations } from "@src/types.generated";
import useUser from "@src/hooks/useUser";
import { useFeatureFlagsQuery } from "@src/contexts/FeatureFlagContext.generated";
import { Label } from "@src/ui/label";
import Fuse from "fuse.js";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@src/ui/accordion";
import { useUpdateEmployeeFeatureFlagsMutation } from "@src/components/layout/topNav/SetEmployeeFeatureFlags.generated";

const SetEmployeeFeatureFlags: React.FC = () => {
  const { data, loading } = useFeatureFlagsQuery();
  const { isStaff } = useUser();
  const [searchTerm, setSearchTerm] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [updateEmployeeFeatureFlagsMutation] =
    useUpdateEmployeeFeatureFlagsMutation({
      refetchQueries: [GraphqlOperations.Query.FeatureFlags],
    });

  const updateEmployeeFeatureFlags = useCallback(
    (name: string, enabled: boolean) => {
      updateEmployeeFeatureFlagsMutation({
        variables: {
          input: {
            featureFlags: [
              {
                name,
                enabled: { value: enabled },
              },
            ],
          },
        },
      });
    },
    [updateEmployeeFeatureFlagsMutation],
  );

  const featureFlags = useMemo(() => {
    if (!data?.FeatureFlags) return [];
    return [...data.FeatureFlags].sort((a, b) => a.name.localeCompare(b.name));
  }, [data?.FeatureFlags]);

  const fuse = useMemo(
    () =>
      new Fuse(featureFlags, {
        keys: ["name"],
        threshold: 0.3,
      }),
    [featureFlags],
  );

  const filteredFeatureFlags = useMemo(() => {
    if (!searchTerm) return featureFlags;
    return fuse.search(searchTerm).map((result) => result.item);
  }, [fuse, searchTerm, featureFlags]);

  const featureFlagToggleRows = useMemo(() => {
    return filteredFeatureFlags.map((f) => (
      <FeatureFlagToggleRow
        key={f.name}
        title={f.name}
        selected={f.enabled}
        onUpdate={(checked) => updateEmployeeFeatureFlags(f.name, checked)}
      />
    ));
  }, [filteredFeatureFlags, updateEmployeeFeatureFlags]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  if (!isStaff) return null;

  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="feature-flags">
        <AccordionTrigger>
          <Text type="P1" fontWeight="SemiBold">
            Feature flags
          </Text>
        </AccordionTrigger>
        <AccordionContent>
          <Input
            ref={searchInputRef}
            type="text"
            placeholder="Search feature flags..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-3"
          />
          {loading ? (
            <Spinner />
          ) : (
            <div className="flex flex-col gap-1.5">{featureFlagToggleRows}</div>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

type FeatureFlagToggleRowProps = {
  title: string;
  selected: boolean;
  onUpdate: (checked: boolean) => void;
};

const FeatureFlagToggleRow: React.FC<FeatureFlagToggleRowProps> = (props) => (
  <div className="flex items-center py-0.5">
    <Label htmlFor={props.title} className="flex-1">
      {props.title}
    </Label>
    <Switch
      id={props.title}
      checked={props.selected}
      onCheckedChange={props.onUpdate}
    />
  </div>
);

gql`
  mutation UpdateEmployeeFeatureFlags(
    $input: UpdateEmployeeFeatureFlagsInput!
  ) {
    updateEmployeeFeatureFlags(input: $input) {
      success
    }
  }
`;

export default SetEmployeeFeatureFlags;
