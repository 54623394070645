import { gql } from "@apollo/client";
import { InitializeBillingModal } from "@components/layout/topNav/InitializeBillingModal";
import SetActionPermissions from "@components/layout/topNav/SetActionPermissions";
import CreateOrgModal from "@components/modals/orgManagement/CreateOrgModal";
import UpdateOrgModal from "@components/modals/orgManagement/UpdateOrgModal";
import Spacer from "@components/ui/Spacer";

import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedButton from "@src/deprecatedDesignSystem/components/Button";
import Text from "@ui/text";
import { useIsStaff } from "@hooks/useIsStaff";
import { useModal } from "@hooks/useModal";
import useUser from "@hooks/useUser";
import ConfirmationModal from "@src/components/modals/ConfirmationModal";
import { useBillingInitialized } from "@src/hooks/billing/useBillingInitialized";
import React from "react";
import { useResetDemoOrgMutation } from "./AdminTools.generated";
import { launchConfetti } from "@src/utils/confetti";
import { useToast } from "@src/hooks/useToast";
import { Button } from "@src/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@src/ui/popover";
import ScrollableFlexGrow from "@src/components/ui/flexBox/ScrollableFlexGrow";
import SetEmployeeFeatureFlags from "@src/components/layout/topNav/SetEmployeeFeatureFlags";
import GlobeIcon from "@src/ui/icons/18px/globe";
import ToggleButton from "@src/deprecatedDesignSystem/components/ToggleButton";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";
import { useUpdateEmployeeFeatureFlagsMutation } from "./SetEmployeeFeatureFlags.generated";
import { GraphqlOperations } from "@src/types.generated";

const AdminTools: React.FC = () => {
  const { isStaff } = useIsStaff();
  if (!isStaff) return null;
  return <NavOrg />;
};

const NavOrg: React.FC = () => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size="icon" variant="outline">
          <GlobeIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="size-96 p-0">
        <AdminMenu />
      </PopoverContent>
    </Popover>
  );
};

const AdminMenu: React.FC = () => {
  const billingInitialized = useBillingInitialized();
  const { showModal } = useModal();
  const { user } = useUser();
  const { addToast } = useToast();
  const [resetDemoOrg, { loading: resetLoading }] = useResetDemoOrgMutation({
    onCompleted: () => {
      launchConfetti();
      addToast({
        iconType: "refresh",
        message:
          "Demo org reset begun. Please check back in 1 hour to make sure everything looks right.",
      });
    },
  });
  const newNavEnabled = useFeatureFlag("new-nav");
  const newHomeEnabled = useFeatureFlag("new-home-page");
  const flagsOn = newNavEnabled && newHomeEnabled;
  const [updateEmployeeFeatureFlagsMutation] =
    useUpdateEmployeeFeatureFlagsMutation({
      refetchQueries: [GraphqlOperations.Query.FeatureFlags],
    });

  return (
    <ScrollableFlexGrow className="h-full" innerStyle={{ padding: 16 }}>
      <div>
        <Text type="P1" fontWeight="Medium" style={{ marginLeft: 4 }}>
          Org ID: {user?.org!.id}
        </Text>
        <Spacer size={12} />
        <AutoLayout
          direction="vertical"
          spaceBetweenItems={12}
          marginBottom={12}
        >
          <AutoLayout spaceBetweenItems={12}>
            <DeprecatedButton
              onClick={() => {
                showModal(<CreateOrgModal />);
              }}
              variant="Primary"
              text="Add org"
              leftIcon="plus"
            />
            <DeprecatedButton
              onClick={() => {
                showModal(<UpdateOrgModal />);
              }}
              variant="Primary"
              text={`Update ${user?.org?.name}`}
              leftIcon="edit"
            />
          </AutoLayout>
          <AutoLayout spaceBetweenItems={12}>
            {!billingInitialized && (
              <DeprecatedButton
                onClick={() => {
                  showModal(<InitializeBillingModal />);
                }}
                variant="Primary"
                text={"Initialize Billing"}
                leftIcon="credit-card"
              />
            )}
            {user?.org?.isDemoOrg && (
              <DeprecatedButton
                onClick={() => {
                  showModal(
                    <ConfirmationModal
                      title="Reset demo org"
                      text="Are you sure you want to reset your demo org? This can take up to two hours to complete."
                      confirmCopy="Yes, reset my demo org"
                      onConfirm={() => {
                        resetDemoOrg();
                      }}
                      confirmLoading={resetLoading}
                    />,
                  );
                }}
                variant="Primary"
                text={"Reset demo org"}
                leftIcon="refresh"
              />
            )}
          </AutoLayout>
        </AutoLayout>
        <SetActionPermissions />
        <div>
          <Text type="P1" fontWeight="SemiBold">
            Toggle new nav and home page
          </Text>
          <ToggleButton
            selected={flagsOn}
            onClick={() => {
              updateEmployeeFeatureFlagsMutation({
                variables: {
                  input: {
                    featureFlags: [
                      {
                        name: "new-nav",
                        enabled: { value: !flagsOn },
                      },
                      {
                        name: "new-home-page",
                        enabled: { value: !flagsOn },
                      },
                    ],
                  },
                },
              });
            }}
          />
        </div>
        <SetEmployeeFeatureFlags />
      </div>
    </ScrollableFlexGrow>
  );
};

export default AdminTools;
gql`
  mutation resetDemoOrg {
    resetDemoOrg {
      success
    }
  }
`;
